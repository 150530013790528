import {
  AccountSubType,
  EarthCoordinateInput,
  MemorabiliaProductType,
  SocialMediaType,
  StoreAthleticLevel,
} from 'api/graphql-global-types';
import { useState, createContext, useCallback } from 'react';

export type SocialAccount = {
  platform: SocialMediaType;
  link: string;
  isAdded: boolean;
};

export type OnboardingFormData = {
  avatar?: File | string;
  profileName?: string;
  username?: string;
  accountType?: { value: AccountSubType; label: string };
  experienceLevel?: {
    value: StoreAthleticLevel;
    label: string;
  };
  sport?: {
    id: number;
    name: string;
  };
  bio?: string;
  phoneNumber?: string;
  phoneOptIn?: boolean;
  location?: {
    city: string | null;
    state?: string | null;
    stateCode?: string | null;
    country: string | null;
    countryCode: string | null;
    coordinates: EarthCoordinateInput | null;
  };
  socialAccounts?: SocialAccount[];
};

type OnboardingContextState = {
  showOnboardingModal: boolean;
  onboardingStep: number;
  onboardingData: OnboardingFormData | null;
  showCreatorSetupModal: boolean;
  /**
   * E-Commerce setup
   */
  showEcommerceSetupModal: boolean;
  ecommerceSetupDone: boolean;
  showRequestMerchModal: boolean;
  requestMerchDone: boolean;
  showCreateProductMemorabiliaModal: boolean;
  productType: MemorabiliaProductType;
  createProductDone: boolean;
  createMemorabiliaDone: boolean;
  showCreateExperienceModal: boolean;
  createExperienceDone: boolean;
  /**
   * Streaming setup
   * */
  showStreamingSetupModal: boolean;
  streamingSetupDone: boolean;
  showCreateStreamModal: boolean;
  createStreamDone: boolean;
  showCreateInterviewModal: boolean;
  createInterviewDone: boolean;
  showHostCalendarModal: boolean;
  hostCalendarDone: boolean;
  showBrandDealsSetupModal: boolean;
  /**
   * Brand deals setup
   * */
  brandDealsSetupStep: number;
  brandDealsSetupDone: boolean;
  // Two onboard procedures
  fullOnboarding: boolean;
  ppvInviteSignUp: boolean;
};

type OnboardingContextHandlers = {
  resetOnboardingState: () => void;
  resetOnboardingStateAfterSwitch: () => void;
  setShowOnboardingModal: (show: boolean) => void;
  setOnboardingStep: (step: number) => void;
  setOnboardingData: (data: Partial<OnboardingFormData>) => void;
  setShowCreatorSetupModal: (show: boolean) => void;
  /**
   * E-Commerce setup
   * */
  setShowEcommerceSetupModal: (show: boolean) => void;
  setEcommerceSetupStep: (step: number) => void;
  setEcommerceSetupDone: (done: boolean) => void;
  setShowRequestMerchModal: (show: boolean) => void;
  setRequestMerchDone: (done: boolean) => void;
  setShowCreateProductMemorabiliaModal: (
    show: boolean,
    type: MemorabiliaProductType
  ) => void;
  setCreateProductDone: (done: boolean) => void;
  setCreateMemorabiliaDone: (done: boolean) => void;
  setShowCreateExperienceModal: (show: boolean) => void;
  setCreateExperienceDone: (done: boolean) => void;
  /**
   * Streaming setup
   * */
  setShowStreamingSetupModal: (show: boolean) => void;
  setStreamingSetupDone: (done: boolean) => void;
  setShowCreateStreamModal: (show: boolean) => void;
  setCreateStreamDone: (done: boolean) => void;
  setShowCreateInterviewModal: (show: boolean) => void;
  setCreateInterviewDone: (done: boolean) => void;
  setShowHostCalendarModal: (show: boolean) => void;
  setHostCalendarDone: (done: boolean) => void;
  setShowBrandDealsSetupModal: (show: boolean) => void;
  /**
   * Brand deals setup
   * */
  setBrandDealsSetupStep: (step: number) => void;
  setBrandDealsSetupDone: (done: boolean) => void;
  /**
   * Pro onboarding
   * */
  setFullOnboarding: (value: boolean) => void;
  setPpvInviteSignUp: (value: boolean) => void;
};

export type OnboardingContextType = OnboardingContextState &
  OnboardingContextHandlers;

const initialState: OnboardingContextState = {
  showOnboardingModal: false,
  onboardingStep: 1,
  onboardingData: null,
  showCreatorSetupModal: false,
  showEcommerceSetupModal: false,
  ecommerceSetupDone: false,
  showRequestMerchModal: false,
  requestMerchDone: false,
  showCreateProductMemorabiliaModal: false,
  productType: MemorabiliaProductType.Memorabilia,
  createProductDone: false,
  createMemorabiliaDone: false,
  showCreateExperienceModal: false,
  createExperienceDone: false,
  showStreamingSetupModal: false,
  streamingSetupDone: false,
  showCreateStreamModal: false,
  createStreamDone: false,
  showCreateInterviewModal: false,
  createInterviewDone: false,
  showHostCalendarModal: false,
  hostCalendarDone: false,
  showBrandDealsSetupModal: false,
  brandDealsSetupStep: 0,
  brandDealsSetupDone: false,
  fullOnboarding: false,
  ppvInviteSignUp: false,
};

export const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType
);

export const OnboardingProvider: React.FC = ({ children }) => {
  const [
    onboardingContext,
    setOnboardingContext,
  ] = useState<OnboardingContextState>(initialState);

  const resetOnboardingState = useCallback(() => {
    setOnboardingContext(initialState);
  }, []);

  const resetOnboardingStateAfterSwitch = useCallback(() => {
    setOnboardingContext((prev) => ({
      ...prev,
      showOnboardingModal: false,
      onboardingStep: 1,
      onboardingData: null,
    }));
  }, []);

  const setShowOnboardingModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showOnboardingModal: show,
    }));
  }, []);

  const setOnboardingStep = useCallback((step: number) => {
    setOnboardingContext((prev) => ({
      ...prev,
      onboardingStep: step,
    }));
  }, []);

  const setOnboardingData = useCallback((data: Partial<OnboardingFormData>) => {
    setOnboardingContext((prev) => ({
      ...prev,
      onboardingData: {
        ...(prev.onboardingData || {}),
        ...(data.avatar && { avatar: data.avatar }),
        ...(data.profileName && { profileName: data.profileName }),
        ...(data.username && { username: data.username }),
        ...(data.accountType && { accountType: data.accountType }),
        ...(data.experienceLevel && { experienceLevel: data.experienceLevel }),
        ...(data.sport && { sport: data.sport }),
        ...(data.bio && { bio: data.bio }),
        ...(data.phoneNumber && { phoneNumber: data.phoneNumber }),
        ...(data.location && { location: data.location }),
        ...(data.socialAccounts && { socialAccounts: data.socialAccounts }),
      },
    }));
  }, []);

  const setShowCreatorSetupModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showCreatorSetupModal: show,
    }));
  }, []);

  const setShowEcommerceSetupModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showEcommerceSetupModal: show,
    }));
  }, []);

  const setEcommerceSetupStep = useCallback((step: number) => {
    setOnboardingContext((prev) => ({
      ...prev,
      ecommerceSetupStep: step,
    }));
  }, []);

  const setEcommerceSetupDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      ecommerceSetupDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowRequestMerchModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showRequestMerchModal: show,
    }));
  }, []);

  const setRequestMerchDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      requestMerchDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowCreateProductMemorabiliaModal = useCallback(
    (show: boolean, type: MemorabiliaProductType) => {
      setOnboardingContext((prev) => ({
        ...prev,
        productType: type,
        showCreateProductMemorabiliaModal: show,
      }));
    },
    []
  );

  const setCreateProductDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      createProductDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setCreateMemorabiliaDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      createMemorabiliaDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowCreateExperienceModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showCreateExperienceModal: show,
    }));
  }, []);

  const setCreateExperienceDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      createExperienceDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowStreamingSetupModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showStreamingSetupModal: show,
    }));
  }, []);

  const setStreamingSetupDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      streamingSetupDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowCreateStreamModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showCreateStreamModal: show,
    }));
  }, []);

  const setCreateStreamDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      createStreamDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowCreateInterviewModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showCreateInterviewModal: show,
    }));
  }, []);

  const setCreateInterviewDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      createInterviewDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowHostCalendarModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showHostCalendarModal: show,
    }));
  }, []);

  const setHostCalendarDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      hostCalendarDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setShowBrandDealsSetupModal = useCallback((show: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      showBrandDealsSetupModal: show,
    }));
  }, []);

  const setBrandDealsSetupStep = useCallback((step: number) => {
    setOnboardingContext((prev) => ({
      ...prev,
      brandDealsSetupStep: step,
    }));
  }, []);

  const setBrandDealsSetupDone = useCallback((done: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      brandDealsSetupDone: done,
      showCreatorSetupModal: !done,
    }));
  }, []);

  const setFullOnboarding = useCallback((value: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      fullOnboarding: value,
    }));
  }, []);

  const setPpvInviteSignUp = useCallback((value: boolean) => {
    setOnboardingContext((prev) => ({
      ...prev,
      ppvInviteSignUp: value,
    }));
  }, []);

  return (
    <OnboardingContext.Provider
      value={{
        ...onboardingContext,
        setShowOnboardingModal,
        setOnboardingStep,
        resetOnboardingState,
        resetOnboardingStateAfterSwitch,
        setOnboardingData,
        setShowCreatorSetupModal,
        /**
         * E-Commerce setup
         */
        setShowEcommerceSetupModal,
        setEcommerceSetupStep,
        setEcommerceSetupDone,
        setShowRequestMerchModal,
        setRequestMerchDone,
        setShowCreateProductMemorabiliaModal,
        setCreateProductDone,
        setCreateMemorabiliaDone,
        setShowCreateExperienceModal,
        setCreateExperienceDone,
        /**
         * Streaming setup
         * */
        setShowStreamingSetupModal,
        setStreamingSetupDone,
        setShowCreateStreamModal,
        setCreateStreamDone,
        setShowCreateInterviewModal,
        setCreateInterviewDone,
        setShowHostCalendarModal,
        setHostCalendarDone,
        setShowBrandDealsSetupModal,
        /**
         * Brand deals setup
         */
        setBrandDealsSetupStep,
        setBrandDealsSetupDone,
        /**
         * Pro onboarding
         * */
        setFullOnboarding,
        setPpvInviteSignUp,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
