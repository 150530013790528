import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import ResizeObserver from 'resize-observer-polyfill';
// Hooks
import {
  useScrollToTopOnLoad,
  useFacebookPixel,
  useGoogleAnalytics,
  useTiktokAnalytics,
} from 'hooks';
// Context
import { AppProvider } from 'context/AppContext';
import { OnboardingProvider } from 'context/OnboardingContext';
// Layouts
import ExternalScripts from 'layouts/ExternalScripts/ExternalScripts';

// TODO: remove line below after removing old styles
import 'styles/globals.scss';
import 'styles3/globals.scss';

const DynamicToast = dynamic(() => import('components/common/Toast/Toast'), {
  ssr: false,
});

// polyfill to support a react-virtuoso package in old browsers
if (process.browser && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  useScrollToTopOnLoad();
  useFacebookPixel();
  useGoogleAnalytics();
  useTiktokAnalytics();

  return (
    <>
      <ExternalScripts />

      <AppProvider>
        <OnboardingProvider>
          <Component {...pageProps} />
        </OnboardingProvider>
      </AppProvider>
      <DynamicToast />
    </>
  );
};

export default App;
