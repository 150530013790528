import { useState, createContext, useEffect, useCallback } from 'react';
// Types
import { GetMerchCartItemsList_getMerchCartItemsList } from 'api/merch/types/GetMerchCartItemsList';
import { GetMemorabiliaBySlug_getMemorabiliaBySlug } from 'api/memorabilia/types/GetMemorabiliaBySlug';
import { UserRole } from 'api/graphql-global-types';
import {
  GetStoreMerchProduct_getStoreMerchProduct,
  GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants,
} from 'api/merch/types/GetStoreMerchProduct';
import { FbAuth_fbAuth_SocialSignUpResponse } from 'api/auth/types/FbAuth';
import { GoogleAuth_googleAuth_SocialSignUpResponse } from 'api/auth/types/GoogleAuth';
import { TwitterAuth_twitterAuth_SocialSignUpResponse } from 'api/auth/types/TwitterAuth';
import { TiktokV2Auth_tiktokV2Auth_SocialSignUpResponse } from 'api/auth/types/TiktokV2Auth';
// Helpers
import {
  addMerchShoppingCartToLocalStorage,
  getMerchShoppingCartFromLocalStorage,
  clearMerchShoppingCartFromLocalStorage,
} from 'helpers/storage';
import {
  setTokenToCookies,
  getTokenFromCookies,
  clearTokenFromCookies,
  setGuestTokenToCookies,
  getGuestTokenFromCookies,
  clearGuestTokenFromCookies,
} from 'helpers/cookies';

export type MerchShoppingCartItem =
  | {
      variantId?: number;
      amount: number;
      merchProductVariant?: GetStoreMerchProduct_getStoreMerchProduct_merchProductVariants & {
        merchProduct?: GetStoreMerchProduct_getStoreMerchProduct;
      };
      memorabilia?: GetMemorabiliaBySlug_getMemorabiliaBySlug;
      memorabiliaId?: string;
    }
  | GetMerchCartItemsList_getMerchCartItemsList;

export type MerchShoppingCart = MerchShoppingCartItem[];

export type Token = {
  id: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  role?: UserRole | null;
};

export type SignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber?: string;
  phoneOptIn: boolean;
  username?: string;
  avatar?: File | null;
};

type AppContextState = {
  token: Token | null;
  guestToken: Token | null;
  showLoginModal: boolean;
  showSignUpModal: boolean;
  showForgotPasswordModal: boolean;
  showResetPasswordModal: boolean;
  showScheduleInterviewModal: boolean;
  isGoogleSdkFailed: boolean;
  merchShoppingCart: MerchShoppingCart;
  withOnboardingWatcher: boolean;
  withParticipantFlow: boolean;
  participantStreamId: string;
  isSocialSignUp: boolean;
  socialSignUpData?:
    | FbAuth_fbAuth_SocialSignUpResponse
    | GoogleAuth_googleAuth_SocialSignUpResponse
    | TwitterAuth_twitterAuth_SocialSignUpResponse
    | TiktokV2Auth_tiktokV2Auth_SocialSignUpResponse
    | null;
  signUpStep: number;
  signUpData?: SignUpData | null;
  scheduleInterviewStep: number;
  scheduleInterviewData?: {
    selectedTimeSlot: string;
    selectedUserId?: string;
    description?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    phoneOptIn?: boolean;
    username?: string;
    avatar?: File | null;
  } | null;
};

type AppContextHandlers = {
  setToken: (token: Token) => void;
  clearToken: () => void;
  setGuestToken: (token: Token) => void;
  clearGuestToken: () => void;
  setAppContext: React.Dispatch<React.SetStateAction<AppContextState>>;
  setLoginModalVisibility: (visibility: boolean) => void;
  setSignUpModalVisibility: (visibility: boolean) => void;
  setForgotPasswordModalVisibility: (visibility: boolean) => void;
  setResetPasswordModalVisibility: (visibility: boolean) => void;
  setScheduleInterviewModalVisibility: (visibility: boolean) => void;
  setGoogleSkdFailedStatus: (status: boolean) => void;
  addMerchItemToShoppingCart: (
    item: MerchShoppingCartItem,
    prevVariantId?: number
  ) => void;
  removeMerchItemFromShoppingCart: (variantId: number) => void;
  removeMemorabiliaItemFromShoppingCart: (memorabiliaId: string) => void;
  clearMerchItemsFromShoppingCart: () => void;
  setWithOnboardingWatcher: (status: boolean) => void;
  setWithParticipantFlow: (status: boolean) => void;
  setParticipantStreamId: (streamId: string) => void;
  setSignUpStep: (step: number) => void;
  setSignUpData: (data: Partial<SignUpData>) => void;
  setIsSocialSignUp: (status: boolean) => void;
  setSocialSignUpData: (
    data:
      | FbAuth_fbAuth_SocialSignUpResponse
      | GoogleAuth_googleAuth_SocialSignUpResponse
      | TwitterAuth_twitterAuth_SocialSignUpResponse
      | TiktokV2Auth_tiktokV2Auth_SocialSignUpResponse
      | null
  ) => void;
  resetSignUpState: () => void;
  setScheduleInterviewStep: (step: number) => void;
  setScheduleInterviewData: (data: {
    selectedTimeSlot: string;
    selectedUserId?: string;
    description?: string;
  }) => void;
  resetScheduleInterviewData: () => void;
};

export type AppContextType = AppContextHandlers & AppContextState;

const initialState = {
  token: getTokenFromCookies(),
  guestToken: getGuestTokenFromCookies(),
  showLoginModal: false,
  showSignUpModal: false,
  showForgotPasswordModal: false,
  showResetPasswordModal: false,
  showAthleteSignUpModal: false,
  showScheduleInterviewModal: false,
  isGoogleSdkFailed: false,
  merchShoppingCart: getMerchShoppingCartFromLocalStorage(),
  withOnboardingWatcher: true,
  withParticipantFlow: false,
  participantStreamId: '',
  isSocialSignUp: false,
  socialSignUpData: null,
  signUpStep: 0,
  signUpData: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    phoneOptIn: false,
    username: '',
    avatar: null,
  },
  scheduleInterviewStep: 0,
  scheduleInterviewData: null,
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppProvider: React.FC = ({ children }) => {
  const [appContext, setAppContext] = useState<AppContextState>(initialState);

  useEffect(() => {
    const token = getTokenFromCookies();

    if (token?.accessToken && token?.refreshToken && !appContext.token) {
      setAppContext((state) => ({
        ...state,
        token,
      }));
    }
  }, [appContext.token]);

  const setToken = (token: Token) => {
    clearGuestToken();
    setTokenToCookies(token);
    setAppContext((state) => ({
      ...state,
      token,
    }));
  };

  const clearToken = () => {
    clearTokenFromCookies();
    setAppContext((state) => ({
      ...state,
      token: null,
    }));
  };

  const setGuestToken = (guestToken: Token) => {
    setGuestTokenToCookies(guestToken);
    setAppContext((state) => ({
      ...state,
      guestToken,
    }));
  };

  const clearGuestToken = () => {
    clearGuestTokenFromCookies();
    setAppContext((state) => ({
      ...state,
      guestToken: null,
    }));
  };

  const addMerchItemToShoppingCart = (
    cartItem: MerchShoppingCartItem | MerchShoppingCart,
    prevVariantId?: number
  ) => {
    if (Array.isArray(cartItem)) {
      setAppContext((state) => {
        return {
          ...state,
          merchShoppingCart: cartItem,
        };
      });
      addMerchShoppingCartToLocalStorage(cartItem);
    } else {
      const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();

      const isMerchAlreadyExists = existingMerchShoppingCart.some((item) => {
        if (item.variantId && cartItem.variantId) {
          return item.variantId === cartItem.variantId;
        }
      });

      const isMemorabiliaAlreadyInCart = existingMerchShoppingCart.some(
        (item) => {
          if (item.memorabiliaId && cartItem.memorabiliaId) {
            return item.memorabiliaId === cartItem.memorabiliaId;
          }
        }
      );

      const updatedShoppingCart =
        isMerchAlreadyExists || isMemorabiliaAlreadyInCart
          ? existingMerchShoppingCart.map((item) => {
              if (
                item.variantId &&
                cartItem.variantId &&
                item.variantId === cartItem.variantId
              ) {
                return { ...cartItem };
              }

              if (
                item.memorabiliaId &&
                cartItem.memorabiliaId &&
                item.memorabiliaId === cartItem.memorabiliaId
              ) {
                return { ...cartItem };
              }

              return item;
            })
          : [cartItem, ...existingMerchShoppingCart];

      const updatedCartWithoutPrevVariant = prevVariantId
        ? updatedShoppingCart.filter((item) => item.variantId !== prevVariantId)
        : updatedShoppingCart;

      setAppContext((state) => {
        return {
          ...state,
          merchShoppingCart: updatedCartWithoutPrevVariant,
        };
      });
      addMerchShoppingCartToLocalStorage(updatedCartWithoutPrevVariant);
    }
  };

  const removeMerchItemFromShoppingCart = (variantId: number) => {
    const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();
    const updatedShoppingCart = existingMerchShoppingCart.filter(
      (item) => item.variantId !== variantId
    );

    setAppContext((state) => {
      return {
        ...state,
        merchShoppingCart: updatedShoppingCart,
      };
    });
    addMerchShoppingCartToLocalStorage(updatedShoppingCart);
  };

  const removeMemorabiliaItemFromShoppingCart = (memorabiliaId: string) => {
    const existingMerchShoppingCart = getMerchShoppingCartFromLocalStorage();
    const updatedShoppingCart = existingMerchShoppingCart.filter(
      (item) => item.memorabiliaId !== memorabiliaId
    );

    setAppContext((state) => {
      return {
        ...state,
        merchShoppingCart: updatedShoppingCart,
      };
    });
    addMerchShoppingCartToLocalStorage(updatedShoppingCart);
  };

  const clearMerchItemsFromShoppingCart = useCallback(() => {
    setAppContext((state) => ({
      ...state,
      merchShoppingCart: [],
    }));
    clearMerchShoppingCartFromLocalStorage();
  }, []);

  const setLoginModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showLoginModal: visibility,
    }));
  }, []);

  const setSignUpModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showSignUpModal: visibility,
    }));
  }, []);

  const setForgotPasswordModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showForgotPasswordModal: visibility,
      }));
    },
    []
  );

  const setResetPasswordModalVisibility = useCallback((visibility: boolean) => {
    setAppContext((state) => ({
      ...state,
      showResetPasswordModal: visibility,
    }));
  }, []);

  const setScheduleInterviewModalVisibility = useCallback(
    (visibility: boolean) => {
      setAppContext((state) => ({
        ...state,
        showScheduleInterviewModal: visibility,
      }));
    },
    []
  );

  const setGoogleSkdFailedStatus = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      isGoogleSdkFailed: status,
    }));
  }, []);

  const setWithOnboardingWatcher = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      withOnboardingWatcher: status,
    }));
  }, []);

  const setWithParticipantFlow = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      withParticipantFlow: status,
    }));
  }, []);

  const setParticipantStreamId = useCallback((streamId: string) => {
    setAppContext((state) => ({
      ...state,
      participantStreamId: streamId,
    }));
  }, []);

  const setSignUpStep = useCallback((step: number) => {
    setAppContext((state) => ({
      ...state,
      signUpStep: step,
    }));
  }, []);

  const setSignUpData = useCallback((data: Partial<SignUpData>) => {
    setAppContext((prevState) => ({
      ...prevState,
      signUpData: {
        ...prevState.signUpData,
        email: data.email ?? '',
        password: data.password ?? '',
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        phoneNumber: data.phoneNumber ?? '',
        phoneOptIn: data.phoneOptIn ?? false,
        username: data.username ?? '',
        ...(data.avatar && {
          avatar: data.avatar,
        }),
      },
    }));
  }, []);

  const setIsSocialSignUp = useCallback((status: boolean) => {
    setAppContext((state) => ({
      ...state,
      isSocialSignUp: status,
    }));
  }, []);

  const setSocialSignUpData = useCallback(
    (
      data:
        | FbAuth_fbAuth_SocialSignUpResponse
        | GoogleAuth_googleAuth_SocialSignUpResponse
        | TwitterAuth_twitterAuth_SocialSignUpResponse
        | TiktokV2Auth_tiktokV2Auth_SocialSignUpResponse
        | null
    ) => {
      setAppContext((state) => ({
        ...state,
        socialSignUpData: data,
      }));
    },
    []
  );

  const resetSignUpState = useCallback(() => {
    setAppContext((state) => ({
      ...state,
      showLoginModal: false,
      showSignUpModal: false,
      isSocialSignUp: false,
      socialSignUpData: null,
      signUpStep: 0,
      signUpData: null,
    }));
  }, []);

  const setScheduleInterviewStep = useCallback((step: number) => {
    setAppContext((state) => ({
      ...state,
      scheduleInterviewStep: step,
    }));
  }, []);

  const setScheduleInterviewData = useCallback(
    (data: { selectedTimeSlot: string; hostId: string }) => {
      setAppContext((state) => ({
        ...state,
        scheduleInterviewData: data,
      }));
    },
    []
  );

  const resetScheduleInterviewData = useCallback(() => {
    setAppContext((state) => ({
      ...state,
      scheduleInterviewData: null,
      showScheduleInterviewModal: false,
    }));
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...appContext,
        setToken,
        clearToken,
        setGuestToken,
        clearGuestToken,
        setAppContext,
        setLoginModalVisibility,
        setSignUpModalVisibility,
        setForgotPasswordModalVisibility,
        setResetPasswordModalVisibility,
        setScheduleInterviewModalVisibility,
        setGoogleSkdFailedStatus,
        addMerchItemToShoppingCart,
        removeMerchItemFromShoppingCart,
        removeMemorabiliaItemFromShoppingCart,
        clearMerchItemsFromShoppingCart,
        setWithOnboardingWatcher,
        setWithParticipantFlow,
        setParticipantStreamId,
        setSignUpStep,
        setSignUpData,
        setIsSocialSignUp,
        setSocialSignUpData,
        resetSignUpState,
        setScheduleInterviewStep,
        setScheduleInterviewData,
        resetScheduleInterviewData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
